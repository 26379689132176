/**
 * @param {object} config
 * @returns {string}
 */
export function objectToCron(config) {
  const { amount, interval, time: cronTime } = config;
  const [hours, minutes] = cronTime.split(":");

  const intervalMap = {
    months: ["*/" + amount, "*"],
    days: ["*", "*/" + amount]
  };

  const cron = [minutes, hours, "*", ...(intervalMap[interval] || ["*", "*"])];
  return cron.join(" ");
}
