import { ref } from "vue";

const formValues = ref();
const reportId = ref();
const exportProject = ref();
const currentExportProject = ref();

export const useReportExport = () => {
  return { formValues, reportId, exportProject, currentExportProject };
};
