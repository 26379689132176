import Config from "@/components/Settings/Config/config";
import { Error } from "@/core/plugins/swal";
import { ref } from "vue";

export const useConfigValues = () => {
  const configValues = ref();
  async function loadConfigValues() {
    try {
      const response = await Config.getAll({ noPagination: true });
      configValues.value = response.data.filter(entry =>
        entry.name.startsWith("ftpConfig-")
      );
    } catch (e) {
      Error(e);
    }
  }

  return { configValues, loadConfigValues };
};
