export const useNavigationEvent = () => {
  /**
   *
   * @param {string} url
   * @param {object} params
   * @param {'_self' | '_blank'} target
   */
  function navigate(url, params = {}, target = "_self") {
    const payload = {
      action: "navigate",
      data: {
        url,
        params,
        target
      }
    };

    window.top.postMessage(payload, "*");
  }

  return { navigate };
};
