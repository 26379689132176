<script setup>
import Card from "@/components/Tools/Card/Card.vue";
import FormHelper from "@/components/Tools/FormHelper/FormHelper.vue";
import { useReportExport } from "@/components/ExternalApps/TransferApp/ReportExports/composables/useReportExport";
import { computed, defineProps, defineEmits, onMounted, ref } from "vue";
import { useConfigValues } from "@/components/ExternalApps/TransferApp/composables/useConfigValues";
import Loader from "@/components/Tools/Loader.vue";
import { useNavigationEvent } from "@/components/ExternalApps/composables/useNavigationEvent";

defineProps({
  saveButtonLabel: {
    type: String,
    required: true
  }
});

const emit = defineEmits(["save"]);

const { formValues } = useReportExport();
const { configValues, loadConfigValues } = useConfigValues();
const { navigate } = useNavigationEvent();

const configValuesOptions = computed(() =>
  configValues.value?.map(config => ({
    value: config.name,
    label: config.value.description
  }))
);

const form = computed(() => [
  {
    name: "reportId",
    type: "hidden"
  },
  {
    name: "description",
    type: "text",
    label: {
      de: "Bezeichnung",
      en: "Description"
    }
  },
  {
    name: "active",
    type: "checkbox",
    label: {
      de: "Aktivieren",
      en: "Activate"
    }
  },
  {
    name: "ftpConfig",
    type: "select",
    label: {
      de: "FTP-Konfiguration",
      en: "FTP configuration"
    },
    infoText: Object.values(configValuesOptions.value || {}).length
      ? ""
      : {
          de: "Du hast keine bestehenden FTP-Konfigurationen. Du kannst eine in den FTP-Einstellungen erstellen.",
          en: "You have no existing existing FTP-configurations. You can create one in the FTP settings"
        },
    infoTextAction: () => {
      const url = "/app/settings";
      const params = {
        menu: "administration",
        submenu: "connect-transfer"
      };
      const target = "_blank";

      navigate(url, params, target);
    },
    options: configValuesOptions.value,
    validations: {
      required: true
    }
  },
  {
    name: "interval",
    type: "select",
    label: {
      de: "Interval",
      en: "Interval"
    },
    options: [
      {
        value: "days",
        label: {
          de: "Alle X Tage",
          en: "Every X days"
        }
      },
      {
        value: "months",
        label: {
          de: "Alle X Monate",
          en: "Every X months"
        }
      }
    ],
    colClass: "col-4",
    validations: {
      required: true
    }
  },
  {
    name: "amount",
    label: {
      de: "X",
      en: "Interval"
    },
    type: "number",
    colClass: "col-4",
    validations: {
      required: true
    }
  },
  {
    name: "time",
    label: {
      de: "Uhrzeit",
      en: "Time"
    },
    type: "time",
    colClass: "col-4",
    validations: {
      required: true
    }
  },
  {
    name: "path",
    type: "text",
    placeholder: {
      de: "Dateipfad",
      en: "File path"
    },
    label: {
      de: "Dateipfad",
      en: "File path"
    },
    validations: {
      required: true
    }
  },
  {
    name: "filename",
    type: "text",
    placeholder: {
      de: "Dateiname",
      en: "File name"
    },
    label: {
      de: "Dateiname",
      en: "File name"
    },
    infoText: {
      de: "Variablen: {TIMESTAMP}, {DATUM}, Wenn leer, wird der Standardname verwendet.",
      en: "Variables: {TIMESTAMP}, {DATUM}, If empty, the default name is used."
    },
    validations: {
      required: true
    }
  }
]);

const formRef = ref();
const isMounted = ref(false);

const formConfig = {
  labelStacked: true
};

function onSave() {
  const response = formRef.value.validate();

  if (response !== true) {
    return;
  }

  emit("save");
}

onMounted(async () => {
  await loadConfigValues();
  isMounted.value = true;
});
</script>

<template>
  <Card class="ftp-card pa-6">
    <Loader v-if="!isMounted" />
    <template v-else>
      <FormHelper
        ref="formRef"
        v-model="formValues"
        class="report-exports-form"
        :form="form"
        :config="formConfig"
      />

      <div class="ftp-card__actions">
        <button class="btn btn-primary" @click="onSave">
          {{ saveButtonLabel }}
        </button>
      </div>
    </template>
  </Card>
</template>

<style scoped lang="scss">
:deep(.form-helper) {
  .v-picker {
    max-width: none;
  }

  .form-field {
    .col-form-label {
      padding: 0;
    }

    .label-wrapper {
      display: grid;
      gap: 2px;
      margin-bottom: 4px;
    }
  }
}

.ftp-container {
  display: flex;
  place-items: center;
}

.ftp-card {
  width: 100%;
  height: 100%;
  border-radius: 0;
  box-shadow: none;
  border: none;
  display: grid;
  gap: 16px;
  grid-template-rows: 1fr auto;
  align-content: space-between;

  .ftp-button {
    position: sticky;
    top: 0;
    width: 100px;
    right: 0;
    align-self: flex-end;
    z-index: 100;
  }
}

.report-exports-form {
  max-width: 45rem;
}
</style>
