import { getCurrentInstance } from "vue";
import i18nService from "@/core/services/i18n.service";
import { useStore } from "@/core/services/store";

export const useLocale = () => {
  const store = useStore();
  const currentInstance = getCurrentInstance();
  async function setLanguage() {
    const locale = store.getters?.xentralConfig?.data?.locale;
    const lang = locale === "en-us" ? "en" : "de";

    await i18nService.setActiveLanguage(lang);

    if (currentInstance?.proxy?.$i18n) {
      currentInstance.proxy.$i18n.locale = lang;
    }
  }

  return { setLanguage };
};
