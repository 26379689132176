<script setup>
import ReportExportsForm from "@/components/ExternalApps/TransferApp/ReportExports/components/ReportExportsForm.vue";
import { objectToCron } from "@/components/ExternalApps/TransferApp/ReportExports/utility/helpers";
import { Error } from "@/core/plugins/swal";
import Project from "@/components/Settings/Projects/project";
import Presets from "@/components/Settings/Presets/presets";
import {
  addEventToLoadingQueue,
  removeEventFromLoadingQueue
} from "@/composables/useLoadingQueue";
import { UPDATE_SELECTED_PROJECT } from "@/core/services/store/switchArea.module";
import { useStore } from "@/core/services/store";
import { useReportExport } from "@/components/ExternalApps/TransferApp/ReportExports/composables/useReportExport";

const store = useStore();
const { formValues, reportId, currentExportProject } = useReportExport();

async function create() {
  addEventToLoadingQueue({ key: "create-report-export" });

  const formValuesCopy = Object.assign({}, formValues.value);
  let createdProject = {};
  formValuesCopy.cron = objectToCron({
    interval: formValuesCopy.interval,
    amount: formValuesCopy.amount,
    time: formValuesCopy.time
  });

  try {
    createdProject = await Project.store({
      name: "ReportsExports - " + formValuesCopy?.description ?? "",
      preset_id: process.env.VUE_APP_TRANSFER_APP_REPORT_EXPORTS_PRESET_ID
    });

    await store.dispatch(UPDATE_SELECTED_PROJECT, createdProject.data);

    await Presets.store({
      name: "reportExport",
      label: "reportExport",
      type: "json",
      value: {
        ...formValuesCopy,
        reportId: reportId.value
      },
      project_id: createdProject.data.id
    });

    currentExportProject.value = {
      ...createdProject.data,
      report_id: reportId.value
    };
  } catch (e) {
    Error(e);
  }

  removeEventFromLoadingQueue({
    key: "create-report-export",
    type: "success",
    prefix: "reportExports",
    name: "saveFinished"
  });
}
</script>

<template>
  <div class="h-100">
    <ReportExportsForm
      :save-button-label="$t('reportExports.create')"
      @save="create"
    />
  </div>
</template>

<style scoped lang="scss"></style>
