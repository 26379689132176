<script setup>
import Loader from "@/components/Tools/Loader.vue";
import { useRoute } from "vue-router/composables";
import { onMounted, ref } from "vue";
import { useReportExport } from "@/components/ExternalApps/TransferApp/ReportExports/composables/useReportExport";
import { useStore } from "@/core/services/store";
import {
  LOAD_PROJECTS,
  UPDATE_SELECTED_PROJECT
} from "@/core/services/store/switchArea.module";
import Edit from "@/components/ExternalApps/TransferApp/ReportExports/views/Edit.vue";
import Create from "@/components/ExternalApps/TransferApp/ReportExports/views/Create.vue";
import Presets from "@/components/Settings/Presets/presets";
import { useLocale } from "@/components/ExternalApps/TransferApp/composables/useLocale";

const store = useStore();
const isLoading = ref(false);
const { reportId, formValues, currentExportProject } = useReportExport();
const route = useRoute();
const { setLanguage } = useLocale();

async function getReportProjects() {
  const projectFilter = [
    {
      key: "preset_id",
      op: "equals",
      value: process.env.VUE_APP_TRANSFER_APP_REPORT_EXPORTS_PRESET_ID
    }
  ];

  await store.dispatch(LOAD_PROJECTS, projectFilter);
  return store.getters.projects;
}

async function getPresetConfigs(projectIds) {
  const presetConfigFilter = [
    {
      key: "name",
      op: "equals",
      value: "reportExport"
    },
    {
      key: "project_id",
      op: "in",
      value: projectIds
    }
  ];
  const presetConfigs = await Presets.getAll(
    { showAll: true, noPagination: true },
    presetConfigFilter
  );

  return presetConfigs?.data ?? [];
}

onMounted(async () => {
  isLoading.value = true;
  formValues.value = undefined;

  await setLanguage();

  const result = await getReportProjects();
  const projectIds = result.map(value => value.id);
  const presetConfigs = await getPresetConfigs(projectIds);
  const exportProjects = result.map(project => ({
    ...project,
    presetConfig: presetConfigs.find(
      presetConfig => presetConfig.project_id === project.id
    )
  }));

  if (!reportId.value) {
    reportId.value = route.query.reportId;
  }

  currentExportProject.value = exportProjects.find(
    project => project.presetConfig?.value?.reportId === reportId.value
  );

  if (currentExportProject.value) {
    await store.dispatch(UPDATE_SELECTED_PROJECT, currentExportProject.value);
  }

  isLoading.value = false;
});
</script>

<template>
  <div>
    <Loader v-if="isLoading" />

    <template v-else>
      <Edit v-if="currentExportProject" />
      <Create v-else />
    </template>
  </div>
</template>

<style scoped lang="scss"></style>
