<script setup>
import ReportExportsForm from "@/components/ExternalApps/TransferApp/ReportExports/components/ReportExportsForm.vue";
import Loader from "@/components/Tools/Loader.vue";
import { onMounted, onUnmounted, ref } from "vue";
import Presets from "@/components/Settings/Presets/presets";
import { useReportExport } from "@/components/ExternalApps/TransferApp/ReportExports/composables/useReportExport";
import {
  addEventToLoadingQueue,
  removeEventFromLoadingQueue
} from "@/composables/useLoadingQueue";
import { objectToCron } from "@/components/ExternalApps/TransferApp/ReportExports/utility/helpers";
import { useStore } from "@/core/services/store";

const { formValues } = useReportExport();
const store = useStore();
const reportExportPreset = ref();
const isLoading = ref(false);

onMounted(async () => {
  isLoading.value = true;

  const response = await Presets.getAll();
  reportExportPreset.value = response.data.find(
    preset => preset.name === "reportExport"
  );

  formValues.value = reportExportPreset.value.value;
  isLoading.value = false;
});

onUnmounted(() => {
  // Reset form values
  formValues.value = {};
});

async function save() {
  addEventToLoadingQueue({ key: "update-report-export" });

  const formValuesCopy = Object.assign({}, formValues.value);
  formValuesCopy.cron = objectToCron({
    interval: formValuesCopy.interval,
    amount: formValuesCopy.amount,
    time: formValuesCopy.time
  });

  await Presets.update(reportExportPreset.value.id, {
    name: "reportExport",
    label: "reportExport",
    type: "json",
    value: formValuesCopy,
    project_id: store.getters.selectedProject.id
  });

  removeEventFromLoadingQueue({
    key: "update-report-export",
    type: "success",
    prefix: "reportExports",
    name: "saveFinished"
  });
}
</script>

<template>
  <div class="h-100">
    <Loader v-if="isLoading" />

    <template v-else>
      <ReportExportsForm
        :save-button-label="$t('reportExports.save')"
        @save="save"
      />
    </template>
  </div>
</template>

<style scoped lang="scss"></style>
